// extracted by mini-css-extract-plugin
export const descWrapper = "product-module--descWrapper--N1abH";
export const title = "product-module--title--XQFsf";
export const pageTitle = "product-module--pageTitle--1y0Er";
export const carouselTitle = "product-module--carouselTitle--2rr49";
export const section = "product-module--section--ojDIX";
export const text = "product-module--text--3-JkM";
export const productNumber = "product-module--productNumber--4a92e";
export const textProduct = "product-module--textProduct--Rz6cy";
export const featuresWrapper = "product-module--featuresWrapper--1Q-UH";
export const sectionWrapper = "product-module--sectionWrapper--1IauM";
export const galleryWrapper = "product-module--galleryWrapper--1rV_I";
export const featureWrapper = "product-module--featureWrapper--2gnwA";
export const featureImg = "product-module--featureImg--4HUbo";
export const rangeTitle = "product-module--rangeTitle--5Tgu4";
export const rangeButton = "product-module--rangeButton--12AdE";
export const rangeMenu = "product-module--rangeMenu--3_75W";
export const rangeSubMenu = "product-module--rangeSubMenu--29mIa";
export const arrow = "product-module--arrow--3wL-k";
export const rangeMenuPart = "product-module--rangeMenuPart--2eWIG";
export const rangeMenuWrapper = "product-module--rangeMenuWrapper--3pwHi";
export const rangeItemsWrapper = "product-module--rangeItemsWrapper--3VB-X";
export const rangeItem = "product-module--rangeItem--2YzNo";
export const margin = "product-module--margin--2oxIg";
export const flexRow = "product-module--flexRow--3iWMA";
export const backButton = "product-module--backButton--lWyu-";
export const productWrapper = "product-module--productWrapper--1hakZ";
export const prodText = "product-module--prodText--2nutf";
export const swiperWrapper = "product-module--swiperWrapper--3Bj3s";
export const sizeImg = "product-module--sizeImg--1HNE7";
export const sizeImgWrapper = "product-module--sizeImgWrapper--2R2x6";
export const swiperButtonPrev = "product-module--swiperButtonPrev--9wcT7";
export const swiperButtonNext = "product-module--swiperButtonNext--2aNvp";
export const swiperButtonDisabled = "product-module--swiper-button-disabled--Dv_DH";
export const swiperCommandsWrapper = "product-module--swiperCommandsWrapper--3gEr4";
export const swiperCommands = "product-module--swiperCommands--2XU85";
export const refBackground = "product-module--refBackground--yHdSX";
export const rangeBackground = "product-module--rangeBackground--12JQu";
export const mobileTitle = "product-module--mobileTitle--3G8jZ";
export const burgerPosition = "product-module--burgerPosition--15ZSy";
export const shopPageWrapper = "product-module--shopPageWrapper--26Lxw";