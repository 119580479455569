import React from "react";
import * as styles from "../../styles/product.module.scss";
import * as ms from "../../styles/ref.module.scss";
import Img from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";

interface Props {
  image?: any;
  alt: string;
  title: string;
  description: any;
  slug?: string;
  url?: string;
  swipe?: boolean;
  scale?: number;
}

export const ProductRef: React.FC<Props> = ({
  image,
  title,
  description,
  alt,
  slug,
  url,
  swipe,
  scale,
}) => {
  return (
    <div className={`${ms.flexColumn} ${ms.imageWrapper}`}>
      {slug && (
        <div className={ms.moreLink}>
          <Link to={`/product/${slug}`}>More</Link>
        </div>
      )}
      <div
        className={` ${styles.textProduct}`}
        style={{ textAlign: "center", marginBottom: "auto" }}
      >
        <BlockContent
          blocks={description}
          className={` ${styles.textProduct}`}
          style={{ textAlign: "center" }}
        />
      </div>

      <p className={ms.title}>{title}</p>
      <div className={ms.imageContainer}>
        <img
          src="/gouttes.svg"
          className={styles.refBackground}
          alt="xorus water"
          style={{
            visibility: swipe ? "hidden" : "visible",
            width: "100%",
            height: "100%",
          }}
        />
        <div className={slug ? ms.imageStar : ms.imageRef}>
          {/* <div> */}
          {image && <Img fluid={image} alt={alt} />}
          {/* {url && <img src={url} alt={alt} className={styles.rangeImg} />} */}
          {url && (
            <img
              src={url}
              alt={alt}
              style={{
                transform: scale ? `scale(${scale})` : undefined,
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
